import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Paper, TextField, Button, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DonationSlideshow from '../components/DonationSlideshow';
import { loadStripe } from '@stripe/stripe-js';
import InfoPopup from '../components/InfoPopup';
// Load your Stripe publishable key
const stripePromise = loadStripe('your_publishable_key_here');

const Home2 = () => {
  const [open, setOpen] = useState(false); // State to control popup

  // Open the popup when the component mounts
  useEffect(() => {
    setOpen(true); // Set the dialog to open as soon as the page loads
  }, []);

  // Function to close the popup
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        {/* Popup (Dialog) */}
        <InfoPopup open={open} handleClose={handleClose} />

        {/* Hero Section with Donation Form */}
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              py: 10,
              px: 4,
            }}
          >
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box sx={{ p: 3 }}>
                  <Typography variant="h2" gutterBottom color="primary.contrastText">
                    First Responder Resource Center
                  </Typography>
                  <Typography variant="h6" gutterBottom color="primary.contrastText">
                    Join us in our mission to reinforce resilience, restore hope, and foster a community where our first responders receive the support they deserve. Together, we can make a difference. Reinforce a hero today.
                  </Typography>
                  <Button variant="contained" color="secondary" href="#about">
                    Learn More
                  </Button>
                </Box>
              </Grid>

              {/* Donation Form - Right */}
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 4, backgroundColor: 'background.paper' }}>
                  <Typography variant="h4" gutterBottom color="primary.main">
                    Help Support Resources For Those Who Support Us All
                  </Typography>
                  <DonationSlideshow />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* About Section */}
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: 'secondary.main',
              color: 'secondary.contrastText',
              py: 8,
              px: 4,
            }}
          >
            <Grid container spacing={4}>
              {/* Image Gallery Section - Left */}
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 3, backgroundColor: 'background.paper' }}>
                  <img
                    src="assets/usa2.jpg"
                    alt="About"
                    style={{ width: '100%', marginBottom: '20px' }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <img
                        src="assets/coulpes1.jpg"
                        alt="About 2"
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <img
                        src="assets/groupTherapy3.jpg"
                        alt="About 3"
                        style={{ width: '100%' }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Text Section - Right */}
              <Grid item xs={12} md={6}>
                <Box sx={{ p: 3 }}>
                  <Typography id="about" variant="h3" gutterBottom color="secondary.contrastText">
                    About Reinforcement 911
                  </Typography>

                  <Typography variant="body1" paragraph color="secondary.contrastText">
                    In the heart of Hermantown, Minnesota, a beacon of hope and support brightly shines – Reinforcement 911. Born out of profound respect and gratitude for first responders, our nonprofit organization is devoted to their mental well-being.
                  </Typography>
                  <Typography variant="body1" paragraph color="secondary.contrastText">
                    At Reinforcement 911, we believe in the power of compassion and empathy as key elements in the healing process. Our core values are deeply rooted in these principles, and we strive to provide a safe space where our heroes can be vulnerable, seek help, and begin their journey towards healing.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Sponsorship Section and Facilities */}
        <Grid item xs={12}>
          <Box sx={{ py: 5, px: 4, backgroundColor: 'tertiary.light' }}>
            <Grid container spacing={4}>
              {/* Sponsorship Text - Left */}
              <Grid item xs={12} md={6}>
                <Box sx={{ p: 3 }}>
                  <Typography variant="h4" gutterBottom color="tertiary.contrastText">
                    Business Sponsorship Opportunities
                  </Typography>
                  <Typography variant="body1" paragraph color="tertiary.contrastText">
                    Please contact us if you are a business and would sponsor our new Fi. We would like to give you a tour of the facility and an overview of our programing. We are looking for sponsorships for different rooms that will be shared with the departments and agencies we serve and other support agencies.
                  </Typography>
                  <Typography variant="body1" paragraph color="tertiary.contrastText">
                    This will allow us to provide space for expanded services and meeting space. We are hoping for support to purchase needed equipment and furnishings and rent. The rooms would be named after the sponsor.
                  </Typography>

                  <Typography variant="h4" gutterBottom sx={{ mt: 4 }} color="tertiary.contrastText">
                    Corporate Sponsorship
                  </Typography>
                  <Typography variant="body1" paragraph color="tertiary.contrastText">
                    Please contact us directly if you would like to sponsor the First Responder Resource Center for a tour and an overview of our programing. The facility would be named after our Corporate Sponsor.
                  </Typography>
                </Box>
              </Grid>

              {/* Facilities List - Right */}
              <Grid item xs={12} md={6}>
                <Box sx={{ p: 3 }}>
                  <Typography variant="h4" gutterBottom color="tertiary.contrastText">
                    Our Facilities
                  </Typography>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color="text.primary">Group Room</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography color="text.primary">
                        Trauma Informed Yoga <br />
                        Couples Therapy <br />
                        Meditation
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color="text.primary">Board Room</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography color="text.primary">
                        Host Critical Incident Stress Management Debriefings <br />
                        Peer Support/Wellness Training <br />
                        Monthly meetings
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color="text.primary">Club House</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography color="text.primary">
                        Family sessions <br />
                        Meeting space for first responder families
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color="text.primary">Rec Room</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography color="text.primary">
                        Family sessions geared towards teens
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography color="text.primary">Decontamination Room</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography color="text.primary">
                        For our first responders to decontaminate before going home or returning to the office
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home2;
