// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Home2 from './pages/Home2';
import Layout from './components/Layout';
import PageNotFound from './pages/PageNotFound';
import Stories from './pages/Stories.jsx';
import StoryDetailsPage from './pages/StoryDetailsPage';
import { Outlet } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
//import StoriesGrid from './pages/StoriesGrid';

const theme = createTheme({
  palette: {
    primary: {
      main: '#274AA1',
      light: '#6781C2',
      dark: '#0B2669',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#B9B9B8',
      light: '#FFFDF2',
      dark: '#909090',
      contrastText: '#000000',
    },
    tertiary: {
      main: '#04020A',
      light: '#373249',
      dark: '#110C1F',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: '#000000',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif',
    h1: {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
    h2: {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
    h3: {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
    h4: {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
    h5: {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
    h6: {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Layout><Outlet /></Layout>}>
            <Route index element={<Home />} />
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/stories" element={<Stories />} />
            <Route path="/stories/:storyId" element={<StoryDetailsPage />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
