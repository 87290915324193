import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Paper, Button, Container } from '@mui/material';

import { Box, Typography, Grid, Card, CardMedia, CardContent,} from '@mui/material';
import { Link } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DonationSlideshow from '../components/DonationSlideshow';
import { loadStripe } from '@stripe/stripe-js';
// import InfoPopup from '../components/InfoPopup';

// Load your Stripe publishable key
const stripePromise = loadStripe('pk_live_51OJivDFGjlWbWILL3TAstsh95u5NoeS3qWddHuqeuVuHqFniylercvXCLz6laSXd2uI46rBQaBOQgAgsODWD9pTA00pgvFtx6U');
const stories = [
  {
    id: 1,
    title: "Story Coming Sooon!",
    description: "Stay tuned for stories from our local first responders!",
    image: "assets/sampleFF.jpg",
    link: "/stories/hero-journey"
  },
  
];

const Home = () => {
  const [open, setOpen] = useState(false);

  // Open the popup when the component mounts
  useEffect(() => {
    setOpen(true);
  }, []);

  // Function to close the popup
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        {/* Hero Section with Distinct Title */}
        <Grid item xs={12}>
        <Paper 
              elevation={3} 
            sx={{ 
            p: { xs: 2, sm: 3 }, 
            paddingTop: 7, 
             mb: 4, 
            borderRadius: '15px'  }}>
          <Typography 
              variant="h2" 
              align="center" 
              sx={{ fontSize: { xs: '2rem', sm: '3rem' } }}>
           Reinforcement911
         </Typography>
        </Paper>

        </Grid>

        {/* Hero Section */}
        <Grid item xs={12}>
          <Box sx={{ py: { xs: 5, md: 10 }, px: { xs: 2, md: 4 } }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box sx={{ p: 3 }}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    sx={{ fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem' } }}
                  >
                    First Responder Resource Center
                  </Typography>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}
                  >
                    Join us in our mission to reinforce resilience, restore hope, and foster a community where our first responders receive the support they deserve. Together, we can make a difference. Reinforce a hero today.
                  </Typography>
                  <Button variant="contained" href="#about" sx={{ mt: 2 }}>
                    Learn More
                  </Button>
                </Box>
              </Grid>

              {/* Donation Form */}
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: { xs: 3, sm: 4 } }}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}
                  >
                    Help Support Resources For Those Who Support Us All
                  </Typography>
                  <DonationSlideshow />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>

       

        {/* About Section */}
        <Grid item xs={12}>
          <Box sx={{ py: { xs: 5, md: 8 }, px: { xs: 2, md: 4 } }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <img
                    src="assets/usa2.jpg"
                    alt="About"
                    style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <img
                        src="assets/groupTherapy5.jpg"
                        alt="About 2"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <img
                        src="assets/groupTherapy3.jpg"
                        alt="About 3"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box sx={{ p: 3 }}>
                  <Typography
                    id="about"
                    variant="h3"
                    gutterBottom
                    sx={{ fontSize: { xs: '1.8rem', sm: '2.5rem' } }}
                  >
                    About Reinforcement 911
                  </Typography>
                  <Typography variant="body1" paragraph>
                    In the heart of Hermantown, Minnesota, a beacon of hope and support brightly shines – Reinforcement 911. Born out of profound respect and gratitude for first responders, our nonprofit organization is devoted to their mental well-being.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    At Reinforcement 911, we believe in the power of compassion and empathy as key elements in the healing process. Our core values are deeply rooted in these principles, and we strive to provide a safe space where our heroes can be vulnerable, seek help, and begin their journey towards healing.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Container maxWidth="lg">
      {/* Stories Preview Section */}
      <Box sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ background: 'linear-gradient(90deg, #1976d2 0%, #64b5f6 100%)',  p: { xs: 2, sm: 3 }, mb: 4, borderRadius:'15px' }}>
      
            <Typography variant="h2" align="center" sx={{ color:"white",fontSize: { xs: '2rem', sm: '3rem' } }}>
              Reinforcement911 Stories
            </Typography>
          </Paper>
        <Grid container spacing={4}>
          {stories.slice(0, 3).map((story) => (
            <Grid item xs={12} md={4} key={story.id}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={story.image}
                  alt={story.title}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {story.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {story.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={story.link}
                    sx={{ mt: 2 }}
                    disabled
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to="/stories"
            disabled
          >
            View All Stories
          </Button>
        </Box>
      </Box>
    </Container>

        {/* Sponsorship Section */}
        <Grid item xs={12}>
          <Box sx={{ py: { xs: 4, md: 5 }, px: { xs: 2, md: 4 } }}>
            <Grid container spacing={4}>
              {/* Sponsorship Text */}
              <Grid item xs={12} md={6}>
                <Box sx={{ p: 3 }}>
                  <Typography variant="h4" gutterBottom>
                    Business Sponsorship Opportunities
                  </Typography>
                  <Typography variant="body1" paragraph>
                  Please contact us if you are a business interested in sponsoring our new facilities. We would love to give you a tour of the facility and an overview of our programming.
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                    Corporate Sponsorship Opportunities
                  </Typography>
                  <Typography variant="body1" paragraph>
                  Please contact us directly if you would like to sponsor the First Responder Resource Center for a tour and an overview of our programming. The facility could be named after our Corporate Sponsor.
                   </Typography>
                </Box>
              </Grid>

              {/* Facilities List - Right */}
              <Grid item xs={12} md={6}>
                <Box sx={{ p: 3 }}>
                  <Typography variant="h4" gutterBottom>
                    Our Facilities
                  </Typography>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Group Room</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Trauma Informed Yoga <br />
                        Couples Therapy <br />
                        Meditation
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Board Room</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Host Critical Incident Stress Management Debriefings <br />
                        Peer Support/Wellness Training <br />
                        Monthly meetings
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Club House</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Family sessions <br />
                        Meeting space for first responder families
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Rec Room</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Family sessions geared towards teens
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Decontamination Room</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        For our first responders to decontaminate before going home or returning to the office
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
