import React from 'react';
import { Paper, Typography, TextField, Button } from '@mui/material';

const ContactUsForm = () => {
    return (
        <Paper elevation={3} sx={{ p: 4, backgroundColor: 'background.paper' }}>
            <Typography variant="h4" gutterBottom color="primary.main">
                Contact Us
            </Typography>
            <form noValidate autoComplete="off">
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                />
                <Button variant="contained" color="tertiary" fullWidth sx={{ mt: 2 }}>
                    Send Message
                </Button>
            </form>
        </Paper>
    );
};

export default ContactUsForm;