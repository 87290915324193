import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import ContactUsModal from './ContactUsModal';

const Header = () => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    const handleOpenContactModal = () => {
        setIsContactModalOpen(true);
    };

    const handleCloseContactModal = () => {
        setIsContactModalOpen(false);
    };

    return (
        <AppBar
            position="static"
            sx={{
                background: 'linear-gradient(90deg, #1976d2 0%, #64b5f6 100%)', // Gradient blue
                marginBottom:"2",
            }}
        >
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Button color="inherit" component={Link} to="/">
                    <Grid item xs={0} md={1} padding={2}>
                        <img
                            src="/assets/reinforcementLogo.png"
                            alt="Northland Psychological Services"
                            style={{ width: '90px', height: 'auto', marginLeft: '8px' }}
                        />
                    </Grid>
                   {/*  <Typography variant="h6" component="div">
                        Reinforcement 911
                    </Typography> */}
                </Button>
                <Button color="inherit" onClick={handleOpenContactModal}>
                    Contact Us
                </Button>
            </Toolbar>
            <ContactUsModal open={isContactModalOpen} onClose={handleCloseContactModal} />
        </AppBar>
    );
};

export default Header;
