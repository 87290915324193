// src/components/Footer.js
import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';

const Footer = () => {
    return (
        <Box sx={{ background: 'linear-gradient(90deg, #1976d2 0%, #64b5f6 100%)',backgroundColor: '', color: 'white', py: 3, mt: 5, textAlign: 'center' }}>
            <Container>
                
                <Typography>
                    Reinforcement 911 - Minnesota's First Responder Resource Center
                </Typography>
                <Typography variant="body2">
                    © 2024 All rights reserved
                </Typography>
                
                <Box sx={{ mt: 2 }}>
                    <Link href="https://twitter.com" color="inherit" sx={{ mx: 1 }}>
                        Twitter
                    </Link>
                    <Link href="https://facebook.com" color="inherit" sx={{ mx: 1 }}>
                        Facebook
                    </Link>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
