import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const stories = [
  {
    id: 1,
    title: "Firefighter Rescue",
    description: "A brief description of Story.",
    image: "/assets/sampleFF.jpg",
  },
  {
    id: 2,
    title: "Story 2",
    description: "A brief description of Story 2.",
    image: "/stories/hero-journey",
  },
  // Add more stories as needed
];

const Stories = () => {
  return (
    <Grid container spacing={4} sx={{ p: 4 }}>
      {stories.map((story) => (
        <Grid item xs={12} sm={6} md={4} key={story.id}>
          <Card>
            <CardMedia component="img" height="140" image={story.image} alt={story.title} />
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {story.title}
              </Typography>
              <Typography variant="body2">{story.description}</Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`/stories/${story.id}`}
                sx={{ mt: 2 }}
              >
                Read More
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Stories;


// Notes:
// 1. Place Markdown files in the `public/stories` folder.
// 2. Install `react-markdown` with `npm install react-markdown`.
// 3. Ensure proper routing for `Home`, `StoriesGrid`, and `StoryDetails`.
