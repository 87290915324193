import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Container, Typography,Button,Link } from '@mui/material';
import Stories from './Stories';  // Adjust if the file is named differently or in a different path

const StoryDetailsPage = () => {
  const { storyId } = useParams();
  const [content, setContent] = useState('');

  useEffect(() => {
    // Dynamically import the Markdown file
    import(`../stories/story${storyId}.md`)
      .then((res) => fetch(res.default))
      .then((res) => res.text())
      .then((text) => setContent(text))
      .catch((err) => console.error("Error loading markdown file:", err));
  }, [storyId]);

  return (
    <Container sx={{ py: 4 }}>

      {/* <Typography variant="h4" gutterBottom>
        Story {storyId}
      </Typography> */}
      <Button
            variant="outlined"
            color="primary"
            component={Link}
            to="/Stories"
          >
            View All Stories
          </Button>
      <ReactMarkdown>{content}</ReactMarkdown>
    </Container>
  );
};

export default StoryDetailsPage;
